// Post listing
.posts {
  .post-teaser {
    @extend %padding-regular;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
    border-bottom: 1px solid $border-color;
    p a:hover {
      @extend %link-hover;
    }
  }
  .excerpt {
    margin-top: 1em;
  }
}
// Pagination
.pagination .button {
  margin: 0 1.5em;
  i {
    vertical-align: middle;
  }
}
.pagination {
  padding: $padding-small $padding-large 0 $padding-large;
  text-align: center;
}
