article,
.comments {
  @extend %padding-regular;
  border-bottom: 1px solid $border-color;
  float: left;
  width: 100%;
}
article {
  header {
    margin-bottom: 6%;
    text-align: center;
  }
  a:hover {
    @extend %link-hover;
  }
  .footnotes {
    font-size: 0.9em;
  }
}
header {
  h1 {
    margin: 0;
  }
  .meta {
    color: rgba($text-color, .5);
    font-size: 0.9em;
    letter-spacing: 0.1em;
    margin: 0;
    text-transform: uppercase;
  }
}
