// https://github.com/aahan/pygments-github-style/blob/master/jekyll-github.css

.highlight ,pre, ,pre,
 .highlight .hll {
  background-color: #f8f8f8;
  padding: 16px;
  border-radius: 3px;
  font: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
  font-size: 0.85em;
  line-height: 1.45;
  word-wrap: normal;
  overflow-y: auto;
}

/* Comment */
.highlight .c {
  color: #999988; font-style: italic;
 }

/* Error */
.highlight .err {
   color: #a61717; background-color: #e3d2d2;
 }

/* Keyword */
.highlight .k {
  font-weight: bold;
}

/* Operator */
.highlight .o {
  font-weight: bold;
}

/* Comment.Multiline */
.highlight .cm {
  color: #999988; font-style: italic;
}

/* Comment.Preproc */
.highlight .cp {
  color: #999999; font-weight: bold;
}

/* Comment.Single */
.highlight .c1 {
  color: #999988; font-style: italic;
}

/* Comment.Special */
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}

/* Generic.Deleted */
.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}

/* Generic.Deleted x */
.highlight .gd .x {
  color: #000000;
  background-color: #ffaaaa;
}

/* Generic.Emph  */
.highlight .ge {
  font-style: italic;
}

/* Generic.Error */
.highlight .gr {
  color: #aa0000;
}

/* Generic.Heading */
.highlight .gh {
  color: #999999;
}

/* Generic.Inserted */
.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}

/* Generic.Inserted x */
.highlight .gi .x {
  color: #000000;
  background-color: #aaffaa;
}

/* Generic.Output */
.highlight .go {
  color: #888888;
}

/* Generic.Prompt */
.highlight .gp {
  color: #555555;
}

/* Generic.Strong */
.highlight .gs {
  font-weight: bold;
}

/* Generic.Subheading */
.highlight .gu {
  color: #800080;
  font-weight: bold;
}

/* Generic.Traceback */
.highlight .gt {
  color: #aa0000;
}

/* Keyword.Constant */
.highlight .kc {
  font-weight: bold;
}

/* Keyword.Declaration */
.highlight .kd {
  font-weight: bold;
}

/* Keyword.Namespace */
.highlight .kn {
  font-weight: bold;
}

/* Keyword.Pseudo */
.highlight .kp {
  font-weight: bold;
}

/* Keyword.Reserved */
.highlight .kr {
  font-weight: bold;
}

/* Ketword.Type */
.highlight .kt {
  color: #445588;
  font-weight: bold;
}

/* Literal.Number */
.highlight .m {
  color: #009999;
}

/* Literal.String */
.highlight .s {
  color: #dd1144;
}

/* Name */
.highlight .n {
  color: #333333;
}

/* Name.Attribute */
.highlight .na {
  color: teal;
}

/* Name.Builtin */
.highlight .nb {
  color: #0086b3;
}

/* Name.Class */
.highlight .nc {
  color: #445588;
  font-weight: bold;
}

/* Name.Constant */
.highlight .no {
  color: teal;
}

/* Name.Entity */
.highlight .ni {
  color: purple;
}

/* Name.Exception */
.highlight .ne {
  color: #990000;
  font-weight: bold;
}

/* Name.Function */
.highlight .nf {
   color: #990000;
   font-weight: bold;
}

/* Name.NameSpace */
.highlight .nn {
  color: #555555;
}

/* Name.Tag */
.highlight .nt {
  color: navy;
}

/* Name.Variable */
.highlight .nv {
  color: teal;
}

/* Operator.Word */
.highlight .ow {
  font-weight: bold;
}

/* Text.Whitespace */
.highlight .w {
  color: #bbbbbb;
}

/* Literal.Number.Float */
.highlight .mf {
  color: #009999;
}

/* Literal.Number.Hex */
.highlight .mh {
  color: #009999;
}

/* Literal.Number.Integer */
.highlight .mi {
  color: #009999;
}

/* Literal.Number.Oct */
.highlight .mo {
  color: #009999;
}

/* Literal.String.Backtick */
.highlight .sb {
  color: #dd1144;
}

/* Literal.String.Char */
.highlight .sc {
  color: #dd1144;
}

/* Literal.String.Doc */
.highlight .sd {
  color: #dd1144;
}

/* Literal.String.Double */
.highlight .s2 {
  color: #dd1144;
}

/* Literal.String.Escape */
.highlight .se {
  color: #dd1144;
}

/* Literal.String.Heredoc */
.highlight .sh {
  color: #dd1144;
}

/* Literal.String.Interpol */
.highlight .si {
  color: #dd1144;
}

/* Literal.String.Other */
.highlight .sx {
  color: #dd1144;
}

/* Literal.String.Regex */
.highlight .sr {
  color: #009926;
}

/* Literal.String.Single */
.highlight .s1 {
  color: #dd1144;
}

/* Literal.String.Symbol */
.highlight .ss {
  color: #990073;
}

/*  Name.Builtin.Pseudo */
.highlight .bp {
  color: #999999;
}

/* Name.Variable.Class */
.highlight .vc {
  color: teal;
}

/* Name.Variable.Global */
.highlight .vg {
  color: teal;
}

/* Name.Variable.Instance */
.highlight .vi {
  color: teal;
}

/* Literal.Number.Integer.Long */
.highlight .il {
  color: #009999;
}

/*  GC */
.highlight .gc {
  color: #999;
  background-color: #EAF2F5;
}
